@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

body {
    background-color: #f5f5f5;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #333;
    font-size: 2rem;
  }
  
  p {
    color: #666;
  }
  
  button {
    background-color: #ff6f00;
    border: none;
  }
  
  button:hover {
    background-color: #ff8c00;
  }
  
  .navbar-brand img {
    max-height: 40px;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .flex-column {
    flex-direction: column !important;
  }
  
  .min-vh-100 {
    min-height: 100vh !important;
  }
  
  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  
  footer {
    width: 100%;
    text-align: center;
  }
  